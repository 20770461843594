<template>
    <div class="rcheck_chart3">
        <div ref="chart1" style="width: 400px; height: 300px;"></div>
        <div ref="chart2" style="width: 400px; height: 300px;"></div>
    </div>
</template>

<script>
import { chartColor, getChartCakeOption } from "./data"
import * as echarts from 'echarts/core';
import { TooltipComponent, LegendComponent } from 'echarts/components';
import { PieChart } from 'echarts/charts';
import { LabelLayout } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';

echarts.use([
    TooltipComponent,
    LegendComponent,
    PieChart,
    CanvasRenderer,
    LabelLayout
]);

export default {
    props: {
        data: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            chart: null,
            chart2: null,
            chartColor,
            chartData: [],
            chartData2: []
        }
    },
    methods: {
        setChartOption() {
            const option = getChartCakeOption(this.chartData, this.chartColor)
            const option2 = getChartCakeOption(this.chartData2, this.chartColor)

            this.chart.setOption(option)
            this.chart2.setOption(option2)
        },
        updateChartData() {
            this.chartData = [
                { value: this.data.articleStatisticsDTO.noWosCount, name: this.$t('result.charts')[2][0][0] },
                { value: this.data.articleStatisticsDTO.wosCount, name: this.$t('result.charts')[2][0][1] },
            ];

            this.chartData2 = [
                { value: this.data.articleStatisticsDTO.noScopusCount, name: this.$t('result.charts')[2][1][0] },
                { value: this.data.articleStatisticsDTO.scopusCount, name: this.$t('result.charts')[2][1][1] },
            ];

            this.setChartOption();
        }
    },
    mounted() {
        this.chart = echarts.init(this.$refs.chart1);
        this.chart2 = echarts.init(this.$refs.chart2);

        this.updateChartData()
    },
    beforeDestroy() {
        if (this.chart) {
            this.chart.dispose();
        }

        if (this.chart2) {
            this.chart2.dispose();
        }
    }
}
</script>

<style lang='scss'></style>