<template>
    <div class="rcheck_chart_references" v-if="data.map.RETRACTED.length > 0">
        <div class="rcheck_chart_references_box">
            <div class="rcheck_chart_references_box_list">
                <div>{{ $t('result.charts')[1] }}</div>
                <div class="rcheck_chart_references_box_list_content">
                    <div v-for="(item, index) in data.map.RETRACTED" :key="index" class="rcheck_chart_references_box_list_content_item">
                        <span class="storng_text">{{ index + 1 }}</span>. {{ item.author }} {{ item.articleTitle }} <span>{{
                            item.journalName }}</span> <span class="storng_text">{{ item.year }}</span>, {{ item.page }}, <span
                            v-if="item.doi != ''">DOI: {{ item.doi }}</span>
                    </div>
                </div>
            </div>
            <div class="rcheck_chart_references_box_img">

            </div>
        </div>
    </div>
</template>

<script>
import { pageEditor } from "../data";

export default {
    props: {
        activeIndex: {
            type: Number,
            default: 0
        },
        data: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        const { result } = pageEditor

        return {
            result,
        }
    },
}
</script>

<style lang='scss'></style>