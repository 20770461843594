<template>
    <div>
        <component :is="currentComponent" :uploadedFile="uploadedFile" :data="rcheckContantData"
            @change:component="changeComponent" @update:data="updateContantData" @update:upload="updateUploadData">
        </component>
    </div>
</template>

<script>
import Upload from './upload.vue';
import Contant from './contant.vue';

export default {
    components: {
        Upload,
        Contant
    },
    data() {
        return {
            currentComponent: 'Upload',
            rcheckContantData: null,
            uploadedFile: null
        }
    },
    methods: {
        changeComponent(component) {
            this.currentComponent = component;
        },
        updateContantData(data) {
            this.rcheckContantData = data;
        },
        updateUploadData(data) {
            this.uploadedFile = data;
        }
    }
}
</script>

<style lang='scss'>
@import "./style.scss";
</style>