<template>
    <div class="rcheck_upload" v-loading="loading">
        <div class="rcheck_upload_title">{{ this.$t('upload.title') }}</div>
        <div class="rcheck_upload_step">
            <div v-for="(item, index) in $t('upload.stepList')" :key="index" class="rcheck_upload_step_item">
                <span class="rcheck_upload_step_item_text">{{ item }}</span>
                <img src="./static/right_arrow.svg" v-if="index < upload.stepList.length - 1"
                    class="rcheck_upload_step_item_arrow">
            </div>
        </div>
        <div class="rcheck_upload_content">
            <div class="rcheck_upload_content_desc">{{ this.$t('upload.desc') }}</div>
            <div class="rcheck_upload_content_upload">
                <el-upload drag :http-request="uploadHttpRequest" :before-upload="onUploadBefore"
                    :on-remove="onUploadRemove" :accept="accept" action="#" :limit="2" :file-list="fileList"
                    :style="uploadedFile ? { filter: 'opacity(0.5)' } : {}">
                    <div class="rcheck_upload_content_upload_box" v-if="!uploadedFile">
                        <img src="./static/add.png" class="rcheck_upload_content_upload_box_add">
                        <div class="rcheck_upload_content_upload_box_text">{{ this.$t('upload.uploadDescribe') }}</div>
                        <div class="rcheck_upload_content_upload_box_format">
                            <img src="./static/pdf.png" class="rcheck_upload_content_upload_box_pdf">
                        </div>
                    </div>
                    <div v-else class="rcheck_upload_content_file">
                        <img src="./static/file.png">
                        <div>{{ uploadedFile.name }}</div>
                    </div>
                </el-upload>
            </div>

            <div class="rcheck_upload_content_btn_box">
                <div class="rcheck_upload_content_btn" @click="changeComponent">
                    <img src="./static/light.png" class="loading-img" />
                    <div>
                        {{ this.$t('upload.btn') }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { pageEditor } from "./data";
import { rcheckUpload } from "./api/index"

export default {
    emits: ["change:component", 'update:upload', 'update:data'],
    props: {
        data: {
            type: Object,
            default: () => ({})
        },
        uploadedFile: {
            type: File,
            default: null
        }
    },
    data() {
        const { upload } = pageEditor

        return {
            upload,
            loading: false,
            uploadType: ['application/pdf'],
            accept: ".pdf"
        }
    },
    computed: {
        fileList() {
            return this.uploadedFile ? [this.uploadedFile] : [];
        }
    },
    methods: {
        async changeComponent() {
            if (!this.uploadedFile) {
                this.$message.warning(this.$t('upload.notUploadedTip'));
                return;
            }

            this.loading = true;

            if (this.data) {
                setTimeout(() => {
                    this.$emit("change:component", "Contant");
                    this.loading = false;
                }, 200);

                return
            }

            try {
                const parsedData = await this.parseUploadedFile(this.uploadedFile);

                this.$emit("update:data", parsedData);
                this.$emit("change:component", "Contant");
            } catch (error) {
                this.$message.error(this.$t('upload.errorAnalyze'));
                console.error(this.$t('upload.errorAnalyze'), error);
            } finally {
                setTimeout(() => {
                    this.loading = false;
                }, 200);
            }
        },
        onUploadBefore(file) {
            if (!this.uploadType.includes(file.type)) {
                this.$message.warning(this.$t('upload.errorUploadType'));
                return false
            }
        },
        async uploadHttpRequest(file) {
            if (this.uploadedFile) {
                this.$emit("update:data", null)
            }

            try {
                this.$emit("update:upload", file.file)
                this.$message.success(this.$t('upload.successUpload'));
            } catch (error) {
                this.$message.error(this.$t('upload.errorUpload'));
                console.error(this.$t('upload.errorUpload'), error);
            }
        },
        onUploadRemove() {
            this.$emit("update:upload", null)
            this.$emit("update:data", null);
        },
        async parseUploadedFile(file) {
            const formData = new FormData();
            formData.append('files', file);

            const response = await rcheckUpload(formData);
            const data = response.data[0];
            const { articleStatisticsDTO, map } = data;

            articleStatisticsDTO.nojournalCount = articleStatisticsDTO.count - articleStatisticsDTO.journalCount;
            articleStatisticsDTO.noWosCount = articleStatisticsDTO.count - articleStatisticsDTO.wosCount;
            articleStatisticsDTO.noScopusCount = articleStatisticsDTO.count - articleStatisticsDTO.scopusCount;
            articleStatisticsDTO.twoTotal = map.LAST_TWO_YEARS.length;
            articleStatisticsDTO.ttofTotal = map.NEARLY_THREE_TO_FIVE_YEARS.length;
            articleStatisticsDTO.fiveTotal = map.OVER_FIVE_YEARS.length;

            map.total = map.ALL_REFERENCE.length;
            map.twoTotal = map.LAST_TWO_YEARS.length;
            map.ttofTotal = map.NEARLY_THREE_TO_FIVE_YEARS.length;
            map.fiveTotal = map.OVER_FIVE_YEARS.length;
            map.retractedTotal = map.RETRACTED.length;

            console.log("解析后的数据：", data);
            return data;
        },
        getTotalArrayLength(obj) {
            let totalLength = 0;

            for (const key in obj) {
                if (Array.isArray(obj[key])) {
                    totalLength += obj[key].length;
                }
            }

            return totalLength;
        }
    }
}
</script>

<style lang='scss'>
@import "./style.scss";
</style>