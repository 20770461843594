<template>
    <div class="rcheck_tab">
        <div v-for="(item, index) in $t('result.tabList')" :key="index"
            :class="index === activeIndex ? 'rcheck_tab_item rcheck_tab_item_active' : 'rcheck_tab_item'"
            @click="changeIndex(index)">
            {{ item }}
        </div>
    </div>
</template>

<script>
import { pageEditor } from "../data";

export default {
    props: {
        activeIndex: {
            type: Number,
            default: 0
        }
    },
    emits: ['change:index'],
    data() {
        const { result } = pageEditor

        return {
            result
        }
    },
    methods: {
        changeIndex(index) {
            this.$emit('change:index', index)
        }
    }
}
</script>

<style lang='scss'></style>