<template>
    <div class="rcheck_gist">
        <div class="rcheck_gist_left">
            {{ gistData }}
            <div class="rcheck_gist_left_total">{{ gistData.total }}</div>
            <div class="rcheck_gist_left_tab">{{ result.totalList[activeIndex] }}</div>
            <div class="rcheck_gist_left_btn">{{ $t('result.btn') }}</div>
        </div>
        <div class="rcheck_gist_right">
            <div class="rcheck_gist_right_describe">{{ $t(`result.describeList[${activeIndex}]`, gistData.describeTotal)
                }}
            </div>
        </div>
    </div>
</template>

<script>
import { pageEditor } from "../data";

export default {
    emits: ['change:index'],
    props: {
        activeIndex: {
            type: Number,
            default: 0
        },
        gistData: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        const { result } = pageEditor

        return {
            result
        }
    }
}
</script>

<style lang='scss'></style>