<template>
    <div ref="chart1" style="width: 400px; height: 400px;"></div>
</template>

<script>
import { loopColor, getChartLoopOption } from "./data"
import * as echarts from 'echarts/core';
import { TooltipComponent, LegendComponent } from 'echarts/components';
import { PieChart } from 'echarts/charts';
import { LabelLayout } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';

echarts.use([
    TooltipComponent,
    LegendComponent,
    PieChart,
    CanvasRenderer,
    LabelLayout
]);

export default {
    props: {
        data: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            chart: null,
            loopColor,
            chartData: []
        }
    },
    methods: {
        setChartOption() {
            const option = getChartLoopOption(this.chartData, this.loopColor)
            this.chart.setOption(option)
        },
        updateChartData() {
            this.chartData = [
                { value: this.data.map.twoTotal, name: this.$t('result.charts')[3][0]},
                { value: this.data.map.ttofTotal, name: this.$t('result.charts')[3][1]},
                { value: this.data.map.fiveTotal, name: this.$t('result.charts')[3][2]}
            ];

            this.setChartOption();
        }
    },
    mounted() {
        this.chart = echarts.init(this.$refs.chart1);

        this.updateChartData()
    },
    beforeDestroy() {
        if (this.chart1) {
            this.chart1.dispose();
        }
    }
}
</script>

<style lang='scss'></style>