<template>
    <div>
        <section class="header-bg">
        </section>

        <div class="page_rcheck_container">
            <div class="rcheck_container">
                <Rcheck></Rcheck>
            </div>
        </div>
    </div>
</template>

<script>
import Rcheck from '@/components/rcheck/index.vue'

export default {
    components: {
        Rcheck
    },
    data() {
        return {

        }
    }
}
</script>

<style lang='scss'>
@import "../../assets/css/minix.scss";

.page_rcheck_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;

    .rcheck_container {
        @include responsive-width(1200px);
    }
}
</style>