<template>
    <div class="rcheck_contant_box">
        <div @click="changeComponent" class="rcheck_contant_back">
            <i class="el-icon-arrow-left"></i>
            <span>{{ $t('result.backBtn') }}</span>
        </div>

        <div class="rcheck_contant">
            <div>
                <Tab :activeIndex="activeIndex" @change:index="changeIndex"></Tab>
            </div>

            <div class="rcheck_gist_box">
                <div class="rcheck_gist">
                    <div class="rcheck_gist_left">
                        <div class="rcheck_gist_left_total">{{ gistData.total }}</div>
                        <div class="rcheck_gist_left_tab">{{ $t('result.totalList')[activeIndex] }}</div>
                        <div class="rcheck_gist_left_btn">{{ $t('result.btn') }}</div>
                    </div>
                    <div class="rcheck_gist_right">
                        <div class="rcheck_gist_right_describe">{{ $t(`result.describeList[${activeIndex}]`,
                            gistData.describeTotal)
                            }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="rcheck_chart_box">
                <Chart :activeIndex="activeIndex" :data="data" ref="chartRef"></Chart>
            </div>
        </div>
    </div>
</template>

<script>
import { pageEditor } from "./data";
import Tab from "./components/tab.vue"
import Gist from "./components/gist.vue"
import Chart from "./components/chart.vue"

export default {
    emits: ["change:component"],
    props: {
        data: {
            type: Object,
            default: () => ({})
        }
    },
    components: {
        Tab,
        Gist,
        Chart
    },
    data() {
        const { result } = pageEditor

        return {
            activeIndex: 0,
            gistData: {},
            result
        }
    },
    methods: {
        changeIndex(index) {
            this.activeIndex = index

            if (this.activeIndex === 0) {
                this.gistData.total = this.data.articleStatisticsDTO.count
                this.gistData.describeTotal = {
                    a: this.data.articleStatisticsDTO.count, b: this.data.articleStatisticsDTO.journalCount, c: this.data.articleStatisticsDTO.count - this.data.articleStatisticsDTO.journalCount
                }
            } else if (this.activeIndex === 1) {
                this.gistData.total = this.data.articleStatisticsDTO.retractionCount
                this.gistData.describeTotal = {
                    a: this.data.articleStatisticsDTO.count, b: this.data.articleStatisticsDTO.journalCount, c: this.data.articleStatisticsDTO.count - this.data.articleStatisticsDTO.journalCount
                }
            } else if (this.activeIndex === 2) {
                this.gistData.total = this.data.articleStatisticsDTO.journalCount
                this.gistData.describeTotal = {
                    a: this.data.articleStatisticsDTO.wosCount, b: this.data.articleStatisticsDTO.scopusCount, c: this.data.articleStatisticsDTO.normalJournalCount
                }

            } else if (this.activeIndex === 3) {
                this.gistData.total = this.data.map.total
                this.gistData.describeTotal = {
                    a: this.data.map.twoTotal, b: this.data.map.ttofTotal, c: this.data.map.fiveTotal
                }
            }

            this.$nextTick(() => {
                this.$refs.chartRef.setRcheckTableData()
            })
        },
        changeComponent() {
            this.gistData = {}
            this.$emit("change:component", "Upload")
        }
    },
    mounted() {
        this.changeIndex(1)
        this.changeIndex(0)
    }
}
</script>

<style lang='scss'>
@import "./style.scss";
</style>