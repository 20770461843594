<template>
    <div class="rcheck_table">
        <div v-for="(item, index) in data" :key="index" class="rcheck_table_item">
            <div class="rcheck_table_item_title">{{ item.title }}</div>
            <div class="rcheck_table_item_value">{{ item.value }}</div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {

        }
    }
}
</script>

<style lang='scss'></style>